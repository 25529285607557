import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { BaseLayoutComponent } from './base-layout.component';
import { AuthService } from '@core/auth/auth.service';
import { NavigationService } from '@core/services/navigation.service';
import { OcUserService } from '@shared/services/oc-user.service';

@Component({
  selector: 'app-simple-layout',
  templateUrl: './simple-layout.component.html',
  styleUrls: ['./home-layout.component.scss'],
})
export class SimpleLayoutComponent extends BaseLayoutComponent {
  constructor(
    protected authService: AuthService,
    protected modalService: NgbModal,
    protected navigationService: NavigationService,
    protected router: Router,
    protected ocUserService: OcUserService,
    protected window: Window
  ) {
    super(authService, modalService, navigationService, router, ocUserService, window);
  }
}
