import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { DatasetMenuModule } from '@features/dataset-menu/dataset-menu.module';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';

@NgModule({
  declarations: [FooterComponent, NavbarComponent, SidebarComponent],
  imports: [CommonModule, DatasetMenuModule, FontAwesomeModule, NgbModule, RouterModule, TranslateModule.forChild()],
  exports: [FooterComponent, NavbarComponent, SidebarComponent],
})
export class LayoutsModule {}
