import { NavigationEnd, Router } from '@angular/router';
import { Component, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';

import { BaseLayoutComponent } from './base-layout.component';
import { AuthService } from '@core/auth/auth.service';
import { AlertService } from '@core/alert/alert.service';
import { IOCUser } from '@core/models';
import { CommunicationService } from '@core/services/communication.service';
import { NavigationService } from '@core/services/navigation.service';
import { NotificationCenterService } from '../notification-center/notification-center.service';
import { isDesktopUp } from '@shared/helpers/helpers';
import { OcUserService } from '@shared/services/oc-user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home-layout',
  templateUrl: './home-layout.component.html',
  styleUrls: ['./home-layout.component.scss'],
})
export class HomeLayoutComponent extends BaseLayoutComponent {
  encryptionKey: string;
  inactivityThreshold$: Observable<boolean>;
  liveData$: Observable<any>;
  modalRef: NgbModalRef;
  showSidebar = true;
  subscriptions = new Subscription();
  user: IOCUser = null;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private alertService: AlertService,
    protected authService: AuthService,
    private commService: CommunicationService,
    private idle: Idle,
    protected modalService: NgbModal,
    protected navigationService: NavigationService,
    private notificationService: NotificationCenterService,
    protected router: Router,
    private translateService: TranslateService,
    protected ocUserService: OcUserService,
    protected window: Window
  ) {
    super(authService, modalService, navigationService, router, ocUserService, window);
    this.setIdleManagement();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.routeEventChange();
    this.commService.connect();
    this.subscriptions.add(this.navigationService.mediaChange$.subscribe(this.handleMediaChange));
    this.subscriptions.add(this.navigationService.notificationClicked$.subscribe(() => this.notificationService.setStateNotifications()));
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  cleanSubscriptionTags = (): void => {
    const tags = this.document.querySelectorAll('.tip.n');
    if (tags.length) {
      tags.forEach(tag => tag?.remove());
    }
  };

  handleMediaChange = (media: string) => (this.showSidebar = isDesktopUp(media));

  setIdleManagement = (): void => {
    this.idle.setIdle(5);
    this.idle.setTimeout(environment.inactivityThreshold);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.subscriptions.add(
      this.idle.onTimeout.subscribe(() => {
        this.logout();
      })
    );
    this.subscriptions.add(
      this.idle.onTimeoutWarning.subscribe(countdown => {
        if (countdown === 60) {
          const message = this.translateService.instant('Inactivity threshold reached, user will be logged out in 60 seconds.');
          this.alertService.warn(message);
        }
      })
    );
    this.idle.watch();
  };

  routeEventChange = (): void => {
    this.subscriptions.add(
      this.router.events.subscribe(ev => {
        if (ev instanceof NavigationEnd) {
          this.cleanSubscriptionTags();
        }
      })
    );
  };
}
